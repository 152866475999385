<template>
  <div>
    <v-row class="my-2" justify="end">
      <v-col><h2>ເງິນມັດຈຳຕຸກນ້ຳຂອງລູກຄ້າ</h2></v-col>
      <v-col>
        <v-btn
            color="primary"
            @click="exportData()"
            :loading="exportLoading"
            :disabled="exportLoading"
        >Export</v-btn
        ></v-col
      >
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາ ຊື່"
          type="text"
          prepend-inner-icon="mdi-magnify"
          v-model="searchCustomer"
          @keyup.enter="fetchData()"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="listbondingfees"
      :disable-pagination="true"
      hide-default-footer
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      :search="searchCustomer"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.sumTotalBondingFees="{ item }">
        <div class="primary-color">
          {{ Intl.NumberFormat().format(item.sumTotalBondingFees) }}
        </div>
      </template>
      <template v-slot:item.sumTotalBalanceBondingFees="{ item }">
        <v-chip color="success">
          {{
            Intl.NumberFormat().format(item.sumTotalBalanceBondingFees)
          }}</v-chip
        >
      </template>
      <template v-slot:item.sumTotalPaymentBondingFees="{ item }">
        <div class="error--text">
          {{ Intl.NumberFormat().format(item.sumTotalPaymentBondingFees) }}
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("HH:mm DD-MM-YYYY") }}
      </template>

      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2 error--text"
          @click="fetchCustomerInvoice(item.id)"
          >mdi-account-check-outline</v-icon
        >
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>

    <template>
      <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchData()"
      ></Pagination>
    </template>

    <!-- =============Bottle From Branch -->
    <ModalView>
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ລາຍການຄືນເງິນມັດຈຳ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field
                outlined
                dense
                clearable
                prepend-inner-icon="mdi-account-search-outline"
                label="ເລກບິນ"
                type="text"
                v-model="search"
                @keyup.enter="fetchCustomerInvoice()"
              >
              </v-text-field>
              <v-data-table
                :headers="invoiceHeadaer"
                :items="invoices"
                :items-per-page="15"
                class="elevation-1"
              >
                <template v-slot:item.created_at="{ item }">
                  {{ moment(item.created_at).format("DD-MM-YYYY") }}
                  <!-- {{item.created_at}} -->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small class="mr-2" @click="OpenModalEdit(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item.id)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" depressed @click="closeInvoice()">
                Close
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>

    <!-- ===========Edit BondingFees-->
    <ModalEdit>
      <template @close="close">
        <v-card :loading="loading">
          <v-card-title>
            <h4>ແກ້ໄຂເງິນມັດຈຳ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="ຈຳນວນຕຸກນ້ຳ *"
                      required
                      v-model="editValue.amount_bottle"
                      type="number"
                      class="input-number"
                      :rules="bottleRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="ຈຳນວນເງິນມັດຈຳ *"
                      required
                      v-model="editValue.total"
                      type="number"
                      class="input-number"
                      :rules="totalRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeModalEdit()">
                Close
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :loading="loading"
                :disabled="loading"
                @click="updateBottleFees()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalEdit>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
import { GetOldValueOnInput } from "@/Helpers/GetValue";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      TableLoading: false,
      exportLoading: false,
      listbondingfees: [],
      invoices: [],
      offset: 12,
      pagination: {},
      per_page: 25,
      search: "",
      searchCustomer: "",
      customerId: "",
      active: false,
      headers: [
        { text: "ລູກຄ້າ", value: "name" },
        { text: "ເງິນມັດຈຳທັງໝົດ", value: "sumTotalBondingFees" },
        { text: "ເງິນມັດຈຳຕົວຈິງ", value: "sumTotalBalanceBondingFees" },
        { text: "ເງິນມັດຈຳທີ່ສົ່ງຄືນ", value: "sumTotalPaymentBondingFees" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
      invoiceHeadaer: [
        { text: "ເລກບິນ", value: "invoice_number" },
        { text: "ຈຳນວນຕຸກນ້ຳ", value: "amount_bottle" },
        { text: "ຈຳນວນເງິນມັດຈຳ", value: "total" },
        { text: "ວັນທີ", value: "created_at" },
        {
          text: "ລາຍລະອຽດ",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      addValue: {},
      editValue: {},
      bottleRules: [
        (v) => !!v || "ກາລຸນາໃສ່ຈຳນວນຕຸກນ້ຳ",
        // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
      ],
      totalRules: [
        (v) => !!v || "ກາລຸນາໃສ່ຈຳນວນເງິນ",
        // v => (v && v.length >= 1 && v.length <= 7) || 'Price must be less than 2 characters',
      ],
      toast: {
        value: true,
        color: "success",
        msg: "Success",
      },
      toast_error: {
        value: true,
        color: "error",
        msg: "Something when wrong!",
      },
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດເງິນມັດຈຳ`,
    }
  },
  methods: {
    fetchData() {
      this.TableLoading = true;
      this.$admin
        .get("list/customer/has/bonding/fees", {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            search: this.searchCustomer,
          },
        })
        .then((res) => {
          setTimeout(() => {
            this.listbondingfees = res.data.success.data;
            this.pagination = res.data.success;
            this.TableLoading = false;
            this.start_menu = false;
            this.end_menu = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    closeInvoice() {
      this.customerId = "";
      this.fetchData();
      this.$store.commit("modalView_State", false);
    },
    fetchCustomerInvoice(id) {
      this.customerId = id;
      this.$store.commit("modalView_State", true);
      this.loading = true;
      this.$admin
        .post("list/bonding/fees", {
          customer_id: this.customerId,
          invoice_number: this.search,
        })
        .then((res) => {
          setTimeout(() => {
            this.invoices = res.data.bondingFees;
            this.fetchData();
            this.loading = false;
          }, 1000);
        })
        .catch(() => {
          this.fetchData();
          this.active = false;
          this.loading = true;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    OpenModalEdit(item) {
      this.editValue = item;
      this.$store.commit("modalEdit_State", true);
    },
    updateBottleFees() {
      if (this.$refs.form.validate() == true) {
        this.editValue.amount = this.editValue.amount_bottle;
        this.$admin
          .put("edit/bonding/fees/" + this.editValue.id, this.editValue)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.editValue = {};
                this.closeModalEdit();
                this.closeInvoice();
                // this.fetchCustomerInvoice();
                this.reset();
                this.$store.commit("Toast_State", this.toast);
              }, 1000);
            }
          })
          .catch(() => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.fetchData();
          });
      }
    },
    closeModalEdit() {
      this.$store.commit("modalEdit_State", false);
    },

    deleteItem(id) {
      this.customerId = id;
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin
        .delete("delete/bonding/fees/" + this.customerId)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.fetchData();
              this.fetchCustomerInvoice();
              this.$store.commit("modalDelete_State", false);
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        })
        .catch(() => {
          this.fetchData();
          this.$store.commit("Toast_State", this.toast_error);
          this.$store.commit("modalDelete_State", false);
          this.loading = false;
        });
    },
    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
          .get(
              "export/bonding-fees-customer",
              {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  "ລາຍງນເງິນມັດຈຳ " +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
    },

    Search() {
      GetOldValueOnInput(this);
    },
  },
  watch: {
    search: function (value) {
      if (value == "") {
        this.fetchCustomerInvoice();
      }
    },
    searchCustomer: function (value) {
      if (value == "") {
        this.fetchData();
      }
    },
  },
  created() {
    // this.viewInvoice();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
