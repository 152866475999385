<template>
  <div>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab href="#tab-1">ເງິນມັດຈຳທັງໝົດຂອງລູກຄ້າ</v-tab>
        <v-tab href="#tab-2">ລໍຖ້າການອະນຸມັດຄືນເງິນ</v-tab>
        <v-tab href="#tab-3">ປະຫວັດການຄືນເງິນ</v-tab>
      </v-tabs>
      <!-- <hr /> -->

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat>
            <v-card-text>
              <BondingFees />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-2">
          <v-card flat>
            <v-card-text>
              <ReturnMoneyCustomer />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-3">
          <v-card flat>
            <v-card-text>
              <ReturnMoneyHistory />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import ReturnMoneyCustomer from "@views/Report/customer/tab/return_money_customer";
import ReturnMoneyHistory from "@views/Report/customer/tab/return_money_history";
import BondingFees from "@views/Report/customer/tab/bonding_fees";
export default {
  // mixins: [manage],
  components: {
    ReturnMoneyCustomer,
    ReturnMoneyHistory,
    BondingFees,
  },
  data() {
    return {
      tab: null,
    };
  },
  created() {
    if (this.$route.query.tab == "bonding-fees") {
      this.tab = "tab-1";
    } else if (this.$route.query.tab == "pending-return-money") {
      this.tab = "tab-2";
    } else if (this.$route.query.tab == "accepted-return-money") {
      this.tab = "tab-3";
    }
  },
  watch: {
    tab: function (value) {
      if (value == "tab-1") {
        this.$router
          .push({
            name: "Return-Money-Customer",
            query: { tab: "bonding-fees" },
          })
          .catch(() => {});
      } else if (value == "tab-2") {
        this.$router
          .push({
            name: "Return-Money-Customer",
            query: { tab: "pending-return-money" },
          })
          .catch(() => {});
      } else if (value == "tab-3") {
        this.$router
          .push({
            name: "Return-Money-Customer",
            query: { tab: "accepted-return-money" },
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
</style>
