<template>
  <div>
    <v-row class="my-2" justify="end">
      <v-col><h2>ຄືນເງິນມັດຈຳໃຫ້ລູກຄ້າ</h2></v-col>
      <v-col>
        <h2>ລວມເງິນ:  {{ Intl.NumberFormat().format(totalMoney) }}</h2>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="returnMoney"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.paid_fee="{ item }">
        <div class="primary-color">
          {{ Intl.NumberFormat().format(item.paid_fee) }}
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip color="success">{{ item.status }}</v-chip>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("HH:mm DD-MM-YYYY") }}
      </template>

      <!--Action -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2 error--text"
          @click="returnMoneyConfirm(item.id)"
          >mdi-account-check-outline</v-icon
        >
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
    </v-data-table>
    <!-- Toast-->
    <v-dialog v-model="active" max-width="500px">
      <v-card>
        <v-card-text>
          <v-card-title>ຢືນຢັນການສົ່ງເງິນມັດຈຳຄືນໃຫ້ລູກຄ້າ ?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeConfirm()"
              >Cancel</v-btn
            >
            <v-btn
              color="blue darken-1"
              text
              :loading="loading"
              :disabled="loading"
              @click="Confirm()"
              >OK</v-btn
            >
            <v-spacer></v-spacer> </v-card-actions
        ></v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      returnMoney: [],
      returnId: "",
      active: false,
      totalMoney:0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "ລູກຄ້າ", value: "customer_name" },
        { text: "ຈຳນວນຜະລິດຕະພັດ", value: "amount_bottle",width: "120" },
        { text: "ຈຳນວນເງິນ", value: "paid_fee" },
        { text: "ວັນທີ", value: "createdDate" },
        { text: "ສະຖານະ", value: "status" },
        { text: "ໝາຍເຫດ", value: "remark" },
        { text: "ຜູ້ສົ່ງຄຳຂໍ", value: "created_by" },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດ ຄືນເງິນມັດຈຳ`,
    }
  },
  methods: {
    fetchData() {
      this.TableLoading = true;
      this.$axios
        .get("finance/list/pending/payment/bonding/fee")
        .then((res) => {
          setTimeout(() => {
            this.returnMoney = res.data.listPendingBondingFee;
            this.totalMoney  = this.returnMoney.reduce((total, item) => total + item.paid_fee, 0);
            this.TableLoading = false;
            this.start_menu = false;
            this.end_menu = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    closeConfirm() {
      this.active = false;
    },
    returnMoneyConfirm(id) {
      this.returnId = id;
      // this.$store.commit("modalDelete_State", true);
      this.active = true;
    },
    Confirm() {
      this.loading = true;
      this.$axios
        .get("finance/approve/payment/bonding/fee/" + this.returnId)
        .then((res) => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.fetchData();
              this.active = false;
              this.loading = false;
              this.$store.commit("Toast_State", this.toast);
            }, 1000);
          }
        })
        .catch(() => {
          this.fetchData();
          this.active = false;
          this.loading = true;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
  },
  created() {
    // this.viewInvoice();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
