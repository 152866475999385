var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',[_c('h2',[_vm._v("ຄືນເງິນມັດຈຳໃຫ້ລູກຄ້າ")])]),_c('v-col',[_c('h2',[_vm._v("ລວມເງິນ: "+_vm._s(Intl.NumberFormat().format(_vm.totalFeeMoney)))])]),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"ຄົ້ນຫາດ້ວຍ ຊື່ລູກຄ້າ","prepend-inner-icon":"mdi-magnify","type":"text"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.searchItem,"footer-props":{
  'items-per-page-options': [10, 20, 30, 40, 50,100]
},"items-per-page":30,"loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.paid_fee",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary-color"},[_vm._v(" "+_vm._s(Intl.NumberFormat().format(item.paid_fee))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" // "),_c('v-chip',{attrs:{"color":"error"}},[_vm._v(_vm._s(item.status))]),_vm._v(" // ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("HH:mm DD-MM-YYYY"))+" ")]}}])},[_vm._v(" // ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }