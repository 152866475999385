<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ຄືນເງິນມັດຈຳໃຫ້ລູກຄ້າ</h2></v-col>
      <v-col>
        <h2>ລວມເງິນ:  {{ Intl.NumberFormat().format(totalFeeMoney) }}</h2>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          clearable
          label="ຄົ້ນຫາດ້ວຍ ຊື່ລູກຄ້າ"
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          type="text"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="searchItem"
      :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 50,100]
  }"
      :items-per-page="30"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.paid_fee="{ item }">
        <div class="primary-color">
          {{ Intl.NumberFormat().format(item.paid_fee) }}
        </div>
      </template>
      //
      <template v-slot:item.status="{ item }">
        // <v-chip color="error">{{ item.status }}</v-chip> //
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.created_at).format("HH:mm DD-MM-YYYY") }}
      </template>
    </v-data-table>
    <!-- Toast-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      TableLoading: false,
      returnMoney: [],
      returnId: "",
      search: null,
      active: false,
      totalFeeMoney:0,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "ລູກຄ້າ", value: "customer_name" },
        { text: "ຈຳນວນຜະລິດຕະພັດ", value: "amount_bottle",width: "120" },
        { text: "ຈຳນວນເງິນ", value: "paid_fee" },
        { text: "ວັນທີ", value: "createdDate" },
        // { text: "ສະຖານະ", value: "status" },
        { text: "ໝາຍເຫດ", value: "remark" },
        { text: "ຜູ້ສົ່ງຄຳຂໍ", value: "created_by" },
        { text: "ຜູ້ອະນຸມັດ", value: "approved_by" },
        // { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດ ປະຫວັດຄືນເງິນມັດຈຳ`,
    }
  },
  methods: {
    fetchData() {
      this.TableLoading = true;
      this.$axios
        .get("finance/list/approved/payment/bonding/fee")
        .then((res) => {
          setTimeout(() => {
            this.returnMoney = res.data.listBondingFee;
            this.totalFeeMoney  = this.returnMoney.reduce((total, item) => total + item.paid_fee, 0);
            this.TableLoading = false;
            this.start_menu = false;
            this.end_menu = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
  },
  computed: {
    searchItem() {
      if (this.search) {
        return this.returnMoney.filter((item) => {
          let filtered = true;
          if (this.search.length > 0) {
            filtered = item.customer_name
              .toLowerCase()
              .includes(this.search.toLowerCase());
          }
          return filtered;
        });
      } else {
        return this.returnMoney;
      }
    },
  },
  created() {
    // this.viewInvoice();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
